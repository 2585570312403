// src/header/header.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function Header({ onLogout, darkMode, toggleDarkMode }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);

    const menuStructure = [
        {
            title: "IA",
            icon: (
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
            ),
            items: [
                { name: "Organisations", path: "/organisations" }
            ]
        },
        {
            title: "Monitoring",
            icon: (
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
            ),
            items: [
                { name: "Logs", path: "/logs" },
                { name: "Tableau de bord", path: "/dashboard" },
                { name: "État des services", path: "/services-health" }
            ]
        },
        {
            title: "Applications",
            icon: (
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zm10 0a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zm10 0a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
            ),
            items: [
                { name: "Catalogue", path: "/applications" }
            ]
        }
    ];

    const handleDropdown = (title) => {
        if (window.innerWidth < 768) {
            setOpenDropdown(openDropdown === title ? null : title);
        } else {
            setOpenDropdown(title);
        }
    };

    const isActivePath = (path) => location.pathname === path;

    // Fermer le dropdown quand on clique en dehors
    useEffect(() => {
        const closeDropdown = (e) => {
            if (!e.target.closest('.dropdown-container')) {
                setOpenDropdown(null);
            }
        };

        if (window.innerWidth >= 768) {
            document.addEventListener('click', closeDropdown);
            return () => document.removeEventListener('click', closeDropdown);
        }
    }, []);

    return (
        <header className="fixed top-0 left-0 right-0 bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-lg z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Barre principale */}
                <div className="flex justify-between h-16">
                    {/* Logo et Navigation */}
                    <div className="flex items-center justify-between w-full md:w-auto">
                        <Link to="/home" className="flex-shrink-0 flex items-center">
                            <span className="text-2xl font-bold bg-clip-text text-transparent animate-gradient-smooth bg-[length:200%_auto] bg-gradient-to-r from-indigo-500 via-purple-600 to-indigo-500">
                                COM AI
                            </span>
                        </Link>

                        {/* Bouton Menu Mobile */}
                        <button
                            onClick={() => {
                                setIsMobileMenuOpen(!isMobileMenuOpen);
                                setOpenDropdown(null);
                            }}
                            className="md:hidden inline-flex items-center justify-center p-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                            {isMobileMenuOpen ? (
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            )}
                        </button>
                    </div>

                    {/* Menu Bureau */}
                    <nav className="hidden md:flex md:items-center md:space-x-4">
                        {menuStructure.map((menu) => (
                            <div key={menu.title} className="relative dropdown-container">
                                <button
                                    onClick={() => handleDropdown(menu.title)}
                                    className={`px-3 py-2 rounded-lg text-sm font-medium inline-flex items-center space-x-2 transition-all duration-200 ${
                                        openDropdown === menu.title
                                            ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400'
                                            : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
                                    }`}
                                >
                                    {menu.icon}
                                    <span>{menu.title}</span>
                                    <svg className={`w-4 h-4 transition-transform duration-200 ${
                                        openDropdown === menu.title ? 'transform rotate-180' : ''
                                    }`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>

                                {/* Dropdown Bureau */}
                                {openDropdown === menu.title && (
                                    <div className="absolute right-0 mt-2 w-48 rounded-xl overflow-hidden shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            {menu.items.map((item) => (
                                                <Link
                                                    key={item.path}
                                                    to={item.path}
                                                    className={`block w-full text-sm ${
                                                        isActivePath(item.path)
                                                            ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400'
                                                            : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600'
                                                    } px-4 py-2`}
                                                    onClick={() => setOpenDropdown(null)}
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* Boutons Dark Mode et Déconnexion */}
                        <div className="hidden md:flex items-center space-x-4">
                            <button
                                onClick={toggleDarkMode}
                                className="p-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                {darkMode ? (
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                    </svg>
                                ) : (
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                    </svg>
                                )}
                            </button>
                            <button
                                onClick={onLogout}
                                className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-purple-600 to-indigo-500 hover:animate-gradient-fast bg-[length:200%_auto] animate-gradient-smooth rounded-lg transition-all duration-200"
                            >
                                Déconnexion
                            </button>
                        </div>
                    </nav>
                </div>

                {/* Menu Mobile */}
                <div className={`md:hidden transition-all duration-300 ease-in-out ${
                    isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                }`}>
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        {menuStructure.map((menu) => (
                            <div key={menu.title} className="py-1">
                                <button
                                    onClick={() => handleDropdown(menu.title)}
                                    className="w-full flex items-center justify-between px-3 py-2 rounded-lg text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                                >
                                    <div className="flex items-center space-x-3">
                                        {menu.icon}
                                        <span>{menu.title}</span>
                                    </div>
                                    <svg 
                                        className={`w-4 h-4 transform transition-transform duration-200 ${
                                            openDropdown === menu.title ? 'rotate-180' : ''
                                        }`}
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>

                                <div className={`transition-all duration-200 ${
                                    openDropdown === menu.title ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                                }`}>
                                    {menu.items.map((item) => (
                                        <Link
                                            key={item.path}
                                            to={item.path}
                                            className={`block px-4 py-2 ml-8 rounded-lg text-sm ${
                                                isActivePath(item.path)
                                                    ? 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400'
                                                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600'
                                            }`}
                                            onClick={() => {
                                                setIsMobileMenuOpen(false);
                                                setOpenDropdown(null);
                                            }}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;