import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function ContextualNav({ organisation, project }) {
    if (!organisation && !project) return null;

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm mb-6">
            {organisation && (
                <div className="border-b border-gray-200 dark:border-gray-700">
                    <div className="px-4 py-3">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                            {organisation.name}
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            Organisation
                        </p>
                    </div>
                </div>
            )}
            
            {project && (
                <div className="px-4 py-3">
                    <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                        Projet en cours
                    </h4>
                    <div className="mt-1">
                        <span className="text-lg font-medium text-gray-900 dark:text-white">
                            {project.projectDetails?.projectName}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContextualNav; 