import { useQuery } from '@tanstack/react-query';
import API_URL from '../config/api';

// Ajout de la fonction getAuthHeaders
const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Bearer ${token}`,
    'Accept': 'application/json'
  };
};

export const useOrganisations = (page = 1, limit = 10) => {
  return useQuery({
    queryKey: ['organisations', page, limit],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/organisations?page=${page}&limit=${limit}`, {
        headers: getAuthHeaders()
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }
  });
};

export const useUsers = (page = 1, limit = 10) => {
  return useQuery({
    queryKey: ['users', page, limit],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/users?page=${page}&limit=${limit}`, {
        headers: getAuthHeaders()
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des utilisateurs');
      return response.json();
    },
    staleTime: 5 * 60 * 1000
  });
};

export const useProjects = (orgId) => {
  return useQuery({
    queryKey: ['projects', orgId],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/organisations/${orgId}/projects`, {
        headers: getAuthHeaders()
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des projets');
      return response.json();
    },
    staleTime: 5 * 60 * 1000
  });
};