import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import ContextualNav from '../components/ContextualNav';
import { Link } from 'react-router-dom';
import API_URL from '../config/api';

const useApi = (endpoint) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(endpoint, {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });
                
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                
                const result = await response.json();
                setData(result.data || result);
            } catch (error) {
                console.error(`Error fetching data from ${endpoint}:`, error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [endpoint, token]);

    return { data, error, isLoading };
};

const AddProjectForm = ({ onSubmit, onCancel }) => {
    const [projectData, setProjectData] = useState({
        projectName: '',
        description: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(projectData);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-800">
                <h3 className="text-lg font-medium text-white mb-4">
                    Nouveau projet
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300 mb-2">
                            Nom du projet
                        </label>
                        <input
                            type="text"
                            name="projectName"
                            value={projectData.projectName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300 mb-2">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={projectData.description}
                            onChange={handleChange}
                            className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            rows="3"
                        />
                    </div>
                    <div className="flex justify-end space-x-3">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="px-4 py-2 text-sm text-gray-300 hover:text-white"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
                        >
                            Créer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const OrganisationDetails = () => {
    const { orgId } = useParams();
    const [organisation, setOrganisation] = useState(null);
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);
    const [editingProject, setEditingProject] = useState(null);

    // Définition des éléments du fil d'Ariane
    const breadcrumbItems = [
        { label: 'Organisations', link: '/organisations' },
        { label: organisation?.name || 'Chargement...', link: '#' }
    ];

    useEffect(() => {
        const fetchOrganisationAndProjects = async () => {
            const token = localStorage.getItem('token');
            setIsLoading(true);
            try {
                // Récupérer les détails de l'organisation
                const orgResponse = await fetch(`${API_URL}/organisations/${orgId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (!orgResponse.ok) throw new Error('Erreur lors de la récupération de l\'organisation');
                const orgData = await orgResponse.json();
                setOrganisation(orgData);

                // Récupérer les projets de l'organisation en utilisant le bon endpoint
                const projectsResponse = await fetch(`${API_URL}/organisations/${orgId}/projects`, {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });
                if (!projectsResponse.ok) throw new Error('Erreur lors de la récupération des projets');
                const projectsData = await projectsResponse.json();
                console.log('Projets reçus:', projectsData); // Debug
                setProjects(projectsData.data || []);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrganisationAndProjects();
    }, [orgId]);

    const handleAddProject = async (projectData) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_URL}/projects/addProject`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    orgId: orgId,
                    projectDetails: projectData
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création du projet');
            }

            const createdProject = await response.json();
            setProjects([...projects, createdProject]);
            setShowAddForm(false);
        } catch (error) {
            console.error('Error:', error);
            alert('Erreur lors de la création du projet: ' + error.message);
        }
    };

    const handleEditProject = async (projectId, updatedData) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_URL}/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    projectDetails: updatedData
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour du projet');
            }

            // Mise à jour locale des projets
            setProjects(projects.map(project => 
                project._id === projectId 
                    ? { ...project, projectDetails: updatedData }
                    : project
            ));
            setEditingProject(null);
        } catch (error) {
            console.error('Error:', error);
            alert('Erreur lors de la mise à jour du projet: ' + error.message);
        }
    };

    const handleDeleteProject = async (projectId) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce projet ?')) return;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_URL}/projects/${projectId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) throw new Error('Erreur lors de la suppression du projet');

            // Mettre à jour la liste des projets
            setProjects(projects.filter(project => project._id !== projectId));
        } catch (error) {
            console.error('Error:', error);
            alert('Erreur lors de la suppression du projet');
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="animate-fade-in">
                <Breadcrumb items={breadcrumbItems} className="animate-fade-in-up" />

                <div className="mb-8 mt-6 animate-fade-in-up" style={{ animationDelay: '0.2s' }}>
                    <h1 className="text-3xl font-bold mb-4 bg-clip-text text-transparent animate-gradient-smooth bg-[length:200%_auto] bg-gradient-to-r from-indigo-500 via-purple-600 to-indigo-500">
                        {organisation?.name || 'Chargement...'}
                    </h1>
                    
                    <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-lg transition-all duration-300 group">
                        <div className="flex items-start space-x-4">
                            <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg group-hover:scale-110 transition-transform duration-300">
                                <svg className="w-6 h-6 text-indigo-600 dark:text-indigo-400 group-hover:animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                </svg>
                            </div>
                            <div className="group-hover:translate-x-1 transition-transform duration-300">
                                <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2 group-hover:text-indigo-500 dark:group-hover:text-indigo-400 transition-colors duration-300">
                                    Gestion des projets IA
                                </h2>
                                <p className="text-gray-600 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-300 transition-colors duration-300">
                                    Cette page liste l'ensemble des projets développés pour les clients. Tous les prompts pour le projet sont accessibles ici.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    onClick={() => setShowAddForm(true)}
                    className="mb-8 inline-flex items-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg transition-colors duration-200"
                >
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                    </svg>
                    Nouveau projet
                </button>

                {isLoading ? (
                    <div className="text-center py-12">
                        <p className="text-gray-500 dark:text-gray-400">Chargement des projets...</p>
                    </div>
                ) : projects.length === 0 ? (
                    <div className="text-center py-12">
                        <p className="text-gray-500 dark:text-gray-400">Aucun projet trouvé pour cette organisation</p>
                    </div>
                ) : (
                    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                        {projects.map((project, index) => (
                            <div 
                                key={project._id} 
                                className="animate-fade-in-up"
                                style={{ animationDelay: `${0.1 * (index + 1)}s` }}
                            >
                                <div className="group relative transform hover:-translate-y-1 transition-all duration-300 rounded-xl">
                                    <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-xl opacity-0 group-hover:opacity-100 transition duration-300 blur-sm group-hover:blur"></div>
                                    <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-sm group-hover:shadow-xl transition-all duration-300">
                                        <Link 
                                            to={`/organisations/${orgId}/project/${project._id}`}
                                            className="block p-6 hover:bg-gray-50/50 dark:hover:bg-gray-700/50 transition-colors duration-200 rounded-t-xl"
                                        >
                                            <div className="flex justify-between items-start">
                                                <div className="space-y-2">
                                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-indigo-500 dark:group-hover:text-indigo-400 transition-colors duration-200">
                                                        {project.projectDetails?.projectName}
                                                    </h3>
                                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                                        {project.projectDetails?.description || "Aucune description"}
                                                    </p>
                                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                                        Voir les détails →
                                                    </p>
                                                </div>
                                                <span className="px-3 py-1 bg-indigo-100 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400 rounded-full text-xs font-medium">
                                                    Projet
                                                </span>
                                            </div>
                                        </Link>

                                        <div className="px-6 py-3 border-t border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-700/50 rounded-b-xl">
                                            <div className="flex justify-end space-x-2">
                                                <button
                                                    onClick={() => setEditingProject(project._id)}
                                                    className="p-2 text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-white dark:hover:bg-gray-600 rounded-lg transition-all duration-200"
                                                    title="Modifier le projet"
                                                >
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteProject(project._id)}
                                                    className="p-2 text-gray-600 dark:text-gray-400 hover:text-red-600 dark:hover:text-red-400 hover:bg-white dark:hover:bg-gray-600 rounded-lg transition-all duration-200"
                                                    title="Supprimer le projet"
                                                >
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {showAddForm && (
                    <AddProjectForm 
                        onSubmit={handleAddProject}
                        onCancel={() => setShowAddForm(false)}
                    />
                )}

                {editingProject && (
                    <EditProjectForm
                        project={projects.find(p => p._id === editingProject)}
                        onSubmit={(updatedData) => handleEditProject(editingProject, updatedData)}
                        onCancel={() => setEditingProject(null)}
                    />
                )}
            </div>
        </div>
    );
};

const EditProjectForm = ({ project, onSubmit, onCancel }) => {
    const [projectData, setProjectData] = useState({
        projectName: project.projectDetails.projectName,
        description: project.projectDetails.description
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(projectData);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-gray-800">
                <h3 className="text-lg font-medium text-white mb-4">
                    Modifier le projet
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300 mb-2">
                            Nom du projet
                        </label>
                        <input
                            type="text"
                            name="projectName"
                            value={projectData.projectName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300 mb-2">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={projectData.description}
                            onChange={handleChange}
                            className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            rows="3"
                        />
                    </div>
                    <div className="flex justify-end space-x-3">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="px-4 py-2 text-sm text-gray-300 hover:text-white"
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
                        >
                            Sauvegarder
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OrganisationDetails;
