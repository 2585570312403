import React from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 p-4">
            <div className="max-w-7xl mx-auto">
                {/* En-tête */}
                <div className="text-center mb-8 md:mb-12">
                    <h1 className="text-2xl md:text-4xl lg:text-5xl font-extrabold text-gray-900 dark:text-white mb-4">
                        <span className="block">Bienvenue sur</span>
                        <span className="block mt-2 bg-clip-text text-transparent animate-gradient-smooth bg-[length:200%_auto] bg-gradient-to-r from-indigo-500 via-purple-600 to-indigo-500 hover:animate-gradient-fast">
                            COM AI
                        </span>
                    </h1>
                    <p className="text-sm md:text-base lg:text-lg text-gray-500 dark:text-gray-400 max-w-xl mx-auto">
                        Gérez vos organisations et projets en toute simplicité
                    </p>
                </div>

                {/* Grille de cartes */}
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6">
                    {/* Carte Organisation */}
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 md:p-6">
                        <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100 dark:bg-indigo-900">
                            <svg className="w-6 h-6 text-indigo-600 dark:text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                            </svg>
                        </div>
                        <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white mb-2 md:mb-4">
                            Organisations
                        </h2>
                        <p className="text-sm md:text-base text-gray-600 dark:text-gray-400 mb-4">
                            Gérez vos organisations et leurs membres efficacement
                        </p>
                        <Link
                            to="/organisations"
                            className="inline-flex items-center text-sm md:text-base text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300"
                        >
                            Voir les organisations
                            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                            </svg>
                        </Link>
                    </div>

                    {/* Carte Tableau de bord */}
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 md:p-6">
                        <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100 dark:bg-indigo-900">
                            <svg className="w-6 h-6 text-indigo-600 dark:text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"/>
                            </svg>
                        </div>
                        <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white mb-2 md:mb-4">
                            Tableau de bord
                        </h2>
                        <p className="text-sm md:text-base text-gray-600 dark:text-gray-400 mb-4">
                            Consultez les statistiques et analyses
                        </p>
                        <Link
                            to="/dashboard"
                            className="inline-flex items-center text-sm md:text-base text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300"
                        >
                            Voir les statistiques
                            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                            </svg>
                        </Link>
                    </div>

                    {/* Carte Utilisateurs */}
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 md:p-6">
                        <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-100 dark:bg-indigo-900">
                            <svg className="w-6 h-6 text-indigo-600 dark:text-indigo-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                        </div>
                        <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white mb-2 md:mb-4">
                            Utilisateurs
                        </h2>
                        <p className="text-sm md:text-base text-gray-600 dark:text-gray-400 mb-4">
                            Gérez les utilisateurs et leurs permissions
                        </p>
                        <Link
                            to="/user"
                            className="inline-flex items-center text-sm md:text-base text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300"
                        >
                            Gérer les utilisateurs
                            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
