import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.api.orbis-france.fr';

function LoginPage({ onLoginSuccess }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await fetch('https://dev.api.orbis-france.fr/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Identifiants invalides');
            }

            const { access_token } = await response.json();
            
            // Vérifier le rôle de l'utilisateur
            const userResponse = await fetch('https://dev.api.orbis-france.fr/users/me', {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                }
            });

            if (!userResponse.ok) {
                throw new Error('Erreur lors de la récupération des informations utilisateur');
            }

            const userData = await userResponse.json();

            // Vérifier si l'utilisateur a les droits nécessaires
            if (userData.role !== 'admin' && userData.role !== 'superAdmin') {
                throw new Error('Accès non autorisé. Seuls les administrateurs peuvent se connecter.');
            }

            // Si tout est ok, on procède à la connexion
            localStorage.setItem('token', access_token);
            localStorage.setItem('userRole', userData.role); // Stocker le rôle pour l'utiliser ailleurs
            onLoginSuccess(access_token);
            navigate('/home');
        } catch (error) {
            console.error('Erreur:', error);
            setError(error.message);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="text-center text-4xl font-extrabold text-gray-900 dark:text-white mb-2">
                    COM AI
                </h2>
                <p className="text-center text-sm text-gray-600 dark:text-gray-400">
                    Connectez-vous à votre compte
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow-xl rounded-2xl sm:px-10 border border-gray-100 dark:border-gray-700">
                    {error && (
                        <div className="mb-4 bg-red-50 dark:bg-red-900/50 border-l-4 border-red-400 p-4 rounded">
                            <p className="text-red-700 dark:text-red-200">{error}</p>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Email
                            </label>
                            <input
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent transition-colors duration-200"
                                placeholder="votre@email.com"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Mot de passe
                            </label>
                            <input
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent transition-colors duration-200"
                                placeholder="••••••••"
                            />
                        </div>

                        <button
                            type="submit"
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800 transition-all duration-200 transform hover:scale-[1.02]"
                        >
                            Se connecter
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
