import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line, Pie, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.api.orbis-france.fr';

function DashboardPage() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState({
        general: null,
        subscription: null,
        registration: null,
        activity: null,
        retention: null
    });

    useEffect(() => {
        const fetchAllStats = async () => {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            };

            try {
                const [
                    generalStats,
                    subscriptionStats,
                    registrationStats,
                    activityStats,
                    retentionStats
                ] = await Promise.all([
                    axios.get(`${API_URL}/users/admin/stats`, { headers }),
                    axios.get(`${API_URL}/users/admin/subscriptions`, { headers }),
                    axios.get(`${API_URL}/users/admin/analytics/registration?period=monthly`, { headers }),
                    axios.get(`${API_URL}/users/admin/analytics/activity`, { headers }),
                    axios.get(`${API_URL}/users/admin/analytics/retention`, { headers })
                ]);

                console.log('Activity Stats:', activityStats.data);

                setStats({
                    general: generalStats.data,
                    subscription: subscriptionStats.data,
                    registration: registrationStats.data,
                    activity: activityStats.data,
                    retention: retentionStats.data
                });
            } catch (err) {
                setError("Erreur lors de la récupération des statistiques");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAllStats();
    }, []);

    if (loading) return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
    );

    if (error) return (
        <div className="text-red-500 text-center p-4">
            {error}
        </div>
    );

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">
                Tableau de bord administrateur
            </h1>

            {/* Statistiques générales */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Total Utilisateurs</h3>
                    <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.general?.totalUsers || 0}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Taux de vérification: {stats.general?.verificationRate?.toFixed(1)}%
                    </p>
                </div>
                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Abonnés Actifs</h3>
                    <p className="text-2xl font-bold text-gray-900 dark:text-white">{stats.general?.activeSubscriptions || 0}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Taux d'abonnement: {stats.general?.subscriptionRate?.toFixed(1)}%
                    </p>
                </div>
                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Rétention</h3>
                    <p className="text-2xl font-bold text-gray-900 dark:text-white">
                        {stats.retention?.retentionRate || 0}%
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Taux d'attrition: {stats.retention?.churnRate || 0}%
                    </p>
                </div>
                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Utilisateurs Actifs</h3>
                    <p className="text-2xl font-bold text-gray-900 dark:text-white">
                        {stats.activity?.recentActivityStats?.activeUsers || 0}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Sur les 30 derniers jours
                    </p>
                </div>
            </div>

            {/* Graphiques */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                        Évolution des inscriptions
                    </h2>
                    {stats.registration && <Line data={getRegistrationChartData(stats.registration)} options={chartOptions} />}
                    <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                        Dernières inscriptions: {stats.registration?.data?.[stats.registration.data.length - 1]?.totalRegistrations || 0}
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                        Répartition des abonnements
                    </h2>
                    {stats.subscription && <Pie data={getSubscriptionChartData(stats.subscription)} options={chartOptions} />}
                    <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                        Total abonnements: {stats.subscription?.totalSubscriptions || 0}
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                        Activité des utilisateurs
                    </h2>
                    {stats.activity && <Bar data={getActivityChartData(stats.activity)} options={chartOptions} />}
                </div>
            </div>
        </div>
    );
}

// Composant pour les cartes de statistiques
function StatCard({ title, value, subtitle }) {
    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">{title}</h3>
            <p className="text-3xl font-bold my-2 text-purple-600 dark:text-purple-400">{value}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{subtitle}</p>
        </div>
    );
}

// Configuration des graphiques
const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                color: 'rgb(156, 163, 175)'
            }
        },
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            titleColor: 'rgb(255, 255, 255)',
            bodyColor: 'rgb(255, 255, 255)',
            padding: 12,
            displayColors: true
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            grid: {
                color: 'rgba(156, 163, 175, 0.1)'
            },
            ticks: {
                color: 'rgb(156, 163, 175)'
            }
        },
        x: {
            grid: {
                color: 'rgba(156, 163, 175, 0.1)'
            },
            ticks: {
                color: 'rgb(156, 163, 175)'
            }
        }
    }
};

// Fonctions pour formater les données des graphiques
function getRegistrationChartData(data) {
    if (!data?.data) return {
        labels: [],
        datasets: [{
            label: 'Inscriptions',
            data: [],
            borderColor: 'rgb(99, 102, 241)',
            backgroundColor: 'rgba(99, 102, 241, 0.5)',
        }]
    };

    // Fonction pour formater la date
    const formatDate = (month, year) => {
        const date = new Date(year, month - 1);
        return new Intl.DateTimeFormat('fr-FR', { month: 'short', year: 'numeric' }).format(date);
    };

    return {
        labels: data.data.map(item => formatDate(item.period.month, item.period.year)),
        datasets: [{
            label: 'Inscriptions',
            data: data.data.map(item => item.totalRegistrations),
            borderColor: 'rgb(99, 102, 241)',
            backgroundColor: 'rgba(99, 102, 241, 0.5)',
            tension: 0.3 // Ajoute une courbe plus douce
        }]
    };
}

function getSubscriptionChartData(data) {
    if (!data) return {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [
                'rgba(52, 211, 153, 0.8)',
                'rgba(239, 68, 68, 0.8)',
                'rgba(251, 191, 36, 0.8)'
            ]
        }]
    };

    const statusLabels = {
        active: 'Actifs',
        canceled: 'Annulés',
        pending: 'En attente'
    };

    return {
        labels: data.subscriptionStats.map(stat => statusLabels[stat.status] || stat.status),
        datasets: [{
            data: data.subscriptionStats.map(stat => stat.count),
            backgroundColor: [
                'rgba(52, 211, 153, 0.8)',
                'rgba(239, 68, 68, 0.8)',
                'rgba(251, 191, 36, 0.8)'
            ]
        }]
    };
}

function getActivityChartData(data) {
    if (!data) return {
        labels: [],
        datasets: [{
            label: 'Activité utilisateurs',
            data: [],
            backgroundColor: []
        }]
    };

    // Extraction des données d'activité
    const verifiedCount = data.verificationStats?.find(s => s._id === true)?.count || 0;
    const unverifiedCount = data.verificationStats?.find(s => s._id === false)?.count || 0;
    const subscribedCount = data.subscriptionStats?.find(s => s._id === true)?.count || 0;
    const unsubscribedCount = data.subscriptionStats?.find(s => s._id === false)?.count || 0;
    const recentActiveUsers = data.recentActivityStats?.activeUsers || 0;

    return {
        labels: ['Vérifiés', 'Non vérifiés', 'Abonnés', 'Non abonnés', 'Actifs récents'],
        datasets: [{
            label: 'Activité utilisateurs',
            data: [verifiedCount, unverifiedCount, subscribedCount, unsubscribedCount, recentActiveUsers],
            backgroundColor: [
                'rgba(52, 211, 153, 0.8)',  // vert pour vérifiés
                'rgba(239, 68, 68, 0.8)',   // rouge pour non vérifiés
                'rgba(99, 102, 241, 0.8)',  // indigo pour abonnés
                'rgba(251, 191, 36, 0.8)',  // jaune pour non abonnés
                'rgba(147, 51, 234, 0.8)'   // violet pour actifs récents
            ]
        }]
    };
}

export default DashboardPage; 