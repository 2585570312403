import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/outline';

const routeConfig = {
    organisations: {
        label: 'Organisations',
        path: '/organisations'
    },
    user: {
        label: 'Utilisateurs',
        path: '/user'
    }
};

function Breadcrumb() {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const [objectNames, setObjectNames] = useState({});
    const [failedRequests, setFailedRequests] = useState(new Set());

    useEffect(() => {
        const fetchObjectNames = async () => {
            const token = localStorage.getItem('token');
            const headers = { 'Authorization': `Bearer ${token}` };
            const namesToFetch = [];

            // Identifier les segments qui nécessitent une requête API
            pathSegments.forEach((segment, index) => {
                if (segment.length > 8 && !failedRequests.has(segment)) {
                    const prevSegment = pathSegments[index - 1];
                    if (prevSegment === 'organisations' || prevSegment === 'project') {
                        namesToFetch.push({ id: segment, type: prevSegment });
                    }
                }
            });

            // Si aucun nom à récupérer, sortir
            if (namesToFetch.length === 0) return;

            try {
                const results = await Promise.all(
                    namesToFetch.map(async ({ id, type }) => {
                        try {
                            const endpoint = type === 'organisations' 
                                ? `https://dev.api.orbis-france.fr/organisations/${id}`
                                : `https://dev.api.orbis-france.fr/projects/${id}`;
                            
                            const response = await fetch(endpoint, { headers });
                            
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            
                            const data = await response.json();
                            return { id, name: data.name || data.projectDetails?.projectName };
                        } catch (error) {
                            console.error(`Erreur pour ${type}/${id}:`, error);
                            setFailedRequests(prev => new Set([...prev, id]));
                            return null;
                        }
                    })
                );

                const newNames = results.reduce((acc, result) => {
                    if (result) {
                        acc[result.id] = result.name;
                    }
                    return acc;
                }, {});

                setObjectNames(prev => ({ ...prev, ...newNames }));
            } catch (error) {
                console.error('Erreur lors de la récupération des noms:', error);
            }
        };

        fetchObjectNames();
    }, [location.pathname]);

    // Fonction pour construire le chemin cumulatif
    const buildPath = (index) => {
        const path = pathSegments.slice(0, index + 1);
        const segment = pathSegments[index];
        const previousSegment = pathSegments[index - 1];

        // Si c'est un ID d'organisation
        if (segment.length > 8 && previousSegment === 'organisations') {
            return `/organisations/${segment}`;
        }

        // Si c'est un ID de projet, on ignore le segment 'project'
        if (segment.length > 8 && pathSegments.includes('project')) {
            const orgId = pathSegments[pathSegments.indexOf('organisations') + 1];
            return `/organisations/${orgId}/project/${segment}`;
        }

        // Pour les segments standards
        if (routeConfig[segment] && routeConfig[segment].path) {
            return routeConfig[segment].path;
        }

        // Pour tous les autres cas
        return '/' + path.join('/');
    };

    // Fonction pour obtenir le label approprié
    const getSegmentLabel = (segment) => {
        // Si c'est un segment connu dans routeConfig
        if (routeConfig[segment]) {
            return routeConfig[segment].label;
        }
        // Si c'est le segment 'project', on l'ignore
        if (segment === 'project') {
            return null;
        }
        // Sinon, retourner le nom de l'objet s'il existe
        return objectNames[segment] || segment;
    };

    return (
        <nav className="flex items-center space-x-2 text-sm mb-6" aria-label="Breadcrumb">
            <Link 
                to="/home"
                className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 p-1 rounded-md transition-colors duration-200"
            >
                <HomeIcon className="w-5 h-5" />
            </Link>
            
            {pathSegments.map((segment, index) => {
                // Ignorer le segment 'project'
                if (segment === 'project') return null;
                
                const path = buildPath(index);
                
                return (
                    <React.Fragment key={index}>
                        <ChevronRightIcon className="w-4 h-4 text-gray-400" />
                        {index === pathSegments.length - 1 ? (
                            <span className="text-gray-900 dark:text-white font-medium px-2 py-1">
                                {getSegmentLabel(segment)}
                            </span>
                        ) : (
                            <Link
                                to={path}
                                className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-2 py-1 rounded-md transition-colors duration-200"
                            >
                                {getSegmentLabel(segment)}
                            </Link>
                        )}
                    </React.Fragment>
                );
            })}
        </nav>
    );
}

export default Breadcrumb; 