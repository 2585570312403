import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import ContextualNav from '../components/ContextualNav';
import API_URL from '../config/api';


// Composant pour l'en-tête du projet
const ProjectHeader = ({ project }) => (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
            {project.projectDetails.projectName}
        </h2>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
            {project.projectDetails.description}
        </p>
        <ProjectIdSection projectId={project._id} />
    </div>
);

// Composant pour la section ID du projet
const ProjectIdSection = ({ projectId }) => (
    <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
        <div className="flex flex-col space-y-2">
            <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Clef du projet
            </span>
            <div className="flex items-center space-x-2">
                <code className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg font-mono text-sm">
                    {projectId}
                </code>
                <CopyButton textToCopy={projectId} />
            </div>
        </div>
    </div>
);

// Composant pour le bouton de copie
const CopyButton = ({ textToCopy }) => (
    <button
        onClick={() => navigator.clipboard.writeText(textToCopy)}
        className="p-2 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 transition-colors duration-200"
        title="Copier l'ID"
    >
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
        </svg>
    </button>
);

// Composant pour la liste des messages
const MessagesList = ({ messages, onEdit, onDelete }) => (
    <div className="space-y-6">
        {messages.map((message, index) => (
            <MessageCard 
                key={message.messageId || index}
                message={message}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        ))}
    </div>
);

// Composant pour un message individuel
const MessageCard = ({ message, onEdit, onDelete }) => (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 hover:shadow-md transition-all duration-300 border border-gray-100 dark:border-gray-700">
        <div className="flex justify-between items-start">
            <div className="flex-1">
                <p className="text-gray-900 dark:text-white mb-2">{message.message}</p>
                <span className="inline-block px-3 py-1 bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-400 rounded-full text-xs font-medium">
                    {message.role}
                </span>
            </div>
            <div className="flex space-x-2">
                <button
                    onClick={() => onEdit(message)}
                    className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300"
                >
                    Modifier
                </button>
                <button
                    onClick={() => onDelete(message.messageId)}
                    className="text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300"
                >
                    Supprimer
                </button>
            </div>
        </div>
    </div>
);

// Composant pour le formulaire d'ajout de message
const AddMessageForm = ({ onSubmit, newMessage, setNewMessage, newRole, setNewRole }) => (
    <div className="mt-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
            Nouveau message
        </h3>
        <form onSubmit={onSubmit} className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Message
                </label>
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    placeholder="Entrez votre message..."
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Rôle
                </label>
                <select
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                >
                    <option value="user">Utilisateur</option>
                    <option value="system">Système</option>
                    <option value="assistant">Assistant</option>
                </select>
            </div>
            <button
                type="submit"
                className="w-full px-6 py-3 bg-indigo-600 hover:bg-indigo-700 text-white rounded-xl transition-colors duration-200"
            >
                Ajouter
            </button>
        </form>
    </div>
);

// Composant de chargement
const LoadingSpinner = () => (
    <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
    </div>
);

// Composant de message d'erreur
const ErrorMessage = ({ message }) => (
    <div className="text-center py-12 bg-red-50 dark:bg-red-900/20 rounded-xl">
        <p className="text-red-600 dark:text-red-400">
            {message}
        </p>
    </div>
);

// Composant principal
function ProjectDetails() {
    const location = useLocation();
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [messages, setMessages] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [currentMessage, setCurrentMessage] = useState({});
    const [editedMessage, setEditedMessage] = useState("");
    const [editedRole, setEditedRole] = useState("");
    const [newMessage, setNewMessage] = useState("");
    const [newRole, setNewRole] = useState("user");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editFormVisible, setEditFormVisible] = useState(false);
    const textareaRef = useRef(null);

    const isSettingsPage = location.pathname.endsWith('/settings');
    const isAnalyticsPage = location.pathname.endsWith('/analytics');

    useEffect(() => {
        if (!projectId || projectId === 'undefined') {
            setError("ID du projet invalide");
            setIsLoading(false);
            return;
        }
        fetchProjectDetails();
    }, [projectId]);

    const fetchProjectDetails = async () => {
        const token = localStorage.getItem('token');
        try {
            setIsLoading(true);
            setError(null);
            
            console.log('Fetching project with ID:', projectId);
            
            const response = await fetch(`${API_URL}/projects/${projectId}`, {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Response error:', errorText);
                throw new Error(errorText || `Erreur ${response.status}: ${response.statusText}`);
            }
            
            const data = await response.json();
            console.log('Project data:', data);
            
            if (!data) {
                throw new Error("Données du projet non trouvées");
            }
            
            setProject(data);
            setMessages(data.messages || []);
        } catch (error) {
            console.error('Erreur détaillée:', error);
            setError(error.message || "Une erreur est survenue lors de la récupération des détails du projet");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (message) => {
        setCurrentMessage(message);
        setEditedMessage(message.message);
        setEditedRole(message.role);
        setEditFormVisible(true);
    };

    const handleDelete = async (messageId) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce message ?')) return;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_URL}/projects/${projectId}/messages/${messageId}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Erreur lors de la suppression');
            setMessages(messages.filter(msg => msg.messageId !== messageId));
        } catch (error) {
            console.error('Erreur:', error);
            alert('Erreur lors de la suppression du message');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        
        try {
            const response = await fetch(`${API_URL}/projects/add-message`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    projectId: projectId,
                    message: newMessage,
                    role: newRole
                })
            });

            if (!response.ok) throw new Error('Erreur lors de l\'ajout du message');

            const newMessageData = await response.json();
            setMessages([...messages, newMessageData]);
            setNewMessage('');
            setNewRole('user');
        } catch (error) {
            console.error('Erreur:', error);
            alert('Erreur lors de l\'ajout du message');
        }
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        
        try {
            const response = await fetch(`https://dev.api.orbis-france.fr/projects/${projectId}/messages/${currentMessage.messageId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    message: editedMessage,
                    role: editedRole
                })
            });

            if (!response.ok) throw new Error('Erreur lors de la modification du message');

            setMessages(messages.map(msg => 
                msg.messageId === currentMessage.messageId 
                    ? { ...msg, message: editedMessage, role: editedRole }
                    : msg
            ));
            
            setEditFormVisible(false);
            setCurrentMessage({});
            setEditedMessage('');
            setEditedRole('');
        } catch (error) {
            console.error('Erreur:', error);
            alert('Erreur lors de la modification du message');
        }
    };

    // Composant EditMessageForm à l'intérieur de ProjectDetails
    const EditMessageForm = () => {
        // Maintenir le focus après le premier rendu
        useEffect(() => {
            if (textareaRef.current) {
                textareaRef.current.focus();
                // Placer le curseur à la fin du texte
                const length = textareaRef.current.value.length;
                textareaRef.current.setSelectionRange(length, length);
            }
        }, []);

        return (
            <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                {/* Overlay avec effet de flou */}
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 backdrop-blur-sm transition-opacity animate-fade-in"></div>

                <div className="flex min-h-full items-center justify-center p-4 sm:p-6">
                    <div className="relative transform overflow-hidden rounded-xl bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 w-full max-w-3xl animate-fade-in-up">
                        {/* En-tête de la modale */}
                        <div className="px-8 pt-8 pb-4 border-b border-gray-200 dark:border-gray-700">
                            <div className="flex items-center justify-between">
                                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white flex items-center space-x-3">
                                    <svg className="w-7 h-7 text-indigo-500 animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                    </svg>
                                    <span>Modifier le message</span>
                                </h3>
                                <button
                                    onClick={() => setEditFormVisible(false)}
                                    className="rounded-lg p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <svg className="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* Corps de la modale */}
                        <form onSubmit={handleSubmitEdit} className="px-8 py-6">
                            <div className="space-y-6">
                                {/* Champ Message */}
                                <div className="group">
                                    <label className="block text-base font-medium text-gray-700 dark:text-gray-300 mb-3">
                                        Message
                                    </label>
                                    <div className="relative">
                                        <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-xl opacity-0 group-hover:opacity-100 transition duration-300 blur"></div>
                                        <textarea
                                            ref={textareaRef}
                                            value={editedMessage}
                                            onChange={(e) => setEditedMessage(e.target.value)}
                                            className="relative w-full px-5 py-4 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white text-base"
                                            rows="6"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Champ Rôle */}
                                <div className="group">
                                    <label className="block text-base font-medium text-gray-700 dark:text-gray-300 mb-3">
                                        Rôle
                                    </label>
                                    <div className="relative">
                                        <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-xl opacity-0 group-hover:opacity-100 transition duration-300 blur"></div>
                                        <select
                                            value={editedRole}
                                            onChange={(e) => setEditedRole(e.target.value)}
                                            className="relative w-full px-5 py-4 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white text-base"
                                        >
                                            <option value="user">Utilisateur</option>
                                            <option value="system">Système</option>
                                            <option value="assistant">Assistant</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Pied de la modale */}
                            <div className="mt-8 flex flex-row-reverse gap-4">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center items-center px-6 py-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 bg-[length:200%_auto] animate-gradient-smooth text-white text-base font-medium rounded-xl transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg min-w-[140px]"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Enregistrer
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setEditFormVisible(false)}
                                    className="inline-flex justify-center items-center px-6 py-3 bg-white dark:bg-gray-600 text-gray-700 dark:text-gray-200 text-base font-medium rounded-xl border border-gray-300 dark:border-gray-500 hover:bg-gray-50 dark:hover:bg-gray-500 transition-all duration-300 min-w-[140px]"
                                >
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    if (!projectId) {
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="text-center py-12 bg-red-50 dark:bg-red-900/20 rounded-xl">
                    <p className="text-red-600 dark:text-red-400">
                        Aucun projet n'a été sélectionné.
                    </p>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (error) {
        return (
            <ErrorMessage message={error} />
        );
    }

    if (!project) return (
        <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
        </div>
    );

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {/* En-tête avec animation */}
            <div className="mb-8 animate-fade-in">
                <div className="flex items-center space-x-4 mb-6">
                    <Breadcrumb />
                </div>
                
                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-lg transition-all duration-300 group">
                    <div className="flex items-start space-x-4">
                        <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg group-hover:scale-110 transition-transform duration-300">
                            <svg className="w-6 h-6 text-indigo-600 dark:text-indigo-400 group-hover:animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                            </svg>
                        </div>
                        <div className="group-hover:translate-x-1 transition-transform duration-300">
                            <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 bg-clip-text text-transparent animate-gradient-smooth bg-[length:200%_auto] bg-gradient-to-r from-indigo-500 via-purple-600 to-indigo-500">
                                {project?.projectDetails?.projectName || 'Chargement...'}
                            </h1>
                            <p className="text-gray-600 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-300 transition-colors duration-300">
                                Entraînement et gestion des conversations de l'IA
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <ContextualNav project={project} />

            {/* Section Messages avec animation */}
            <div className="mt-8 space-y-6 animate-fade-in-up" style={{ animationDelay: '0.2s' }}>
                <section className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transform hover:scale-[1.01] transition-all duration-300">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6 flex items-center space-x-2">
                        <span>Messages</span>
                        <span className="px-3 py-1 bg-indigo-100 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400 rounded-full text-xs font-medium">
                            {messages.length}
                        </span>
                    </h2>
                    <div className="space-y-4">
                        {messages.map((message, index) => (
                            <div 
                                key={message.messageId || index}
                                className="animate-fade-in-up"
                                style={{ animationDelay: `${0.1 * (index + 1)}s` }}
                            >
                                <MessageCard 
                                    message={message}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            </div>
                        ))}
                    </div>
                </section>

                {/* Section Nouveau Message */}
                <section className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transform hover:scale-[1.01] transition-all duration-300">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6 flex items-center space-x-2">
                        <span>Nouveau message</span>
                        <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></div>
                    </h2>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="group">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                Message
                            </label>
                            <div className="relative">
                                <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-xl opacity-0 group-hover:opacity-100 transition duration-300 blur"></div>
                                <textarea
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    className="relative w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                                    placeholder="Entrez votre message..."
                                    rows="4"
                                    required
                                />
                            </div>
                        </div>
                        <div className="group">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                Rôle
                            </label>
                            <div className="relative">
                                <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-xl opacity-0 group-hover:opacity-100 transition duration-300 blur"></div>
                                <select
                                    value={newRole}
                                    onChange={(e) => setNewRole(e.target.value)}
                                    className="relative w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-xl shadow-sm focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                                >
                                    <option value="user">Utilisateur</option>
                                    <option value="system">Système</option>
                                    <option value="assistant">Assistant</option>
                                </select>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full px-6 py-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 bg-[length:200%_auto] animate-gradient-smooth text-white rounded-xl transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg"
                        >
                            Envoyer le message
                        </button>
                    </form>
                </section>
            </div>

            {/* Modal d'édition avec animation */}
            {editFormVisible && (
                <EditMessageForm />
            )}
        </div>
    );
}

export default ProjectDetails;
