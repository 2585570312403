import React, { useState, useEffect } from 'react';
import { MagnifyingGlassIcon as SearchIcon, FunnelIcon as FilterIcon, PlusIcon } from '@heroicons/react/24/outline';
import API_URL from '../config/api';

const USERS_PER_PAGE = 10;

// Constantes pour les rôles
const ROLES = {
    BASIC: 'basicRole',
    ADMIN: 'admin',
    SUPER_ADMIN: 'superAdmin'
};

const ROLES_LABELS = {
    [ROLES.BASIC]: 'Utilisateur',
    [ROLES.ADMIN]: 'Administrateur',
    [ROLES.SUPER_ADMIN]: 'Super Admin'
};

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newUser, setNewUser] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phone: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        isVerified: 'all', // 'all', 'verified', 'unverified'
        hasSubscription: 'all', // 'all', 'active', 'inactive'
        role: 'all' // 'all', 'basicRole', 'adminRole', 'superAdminRole'
    });
    const [showAddForm, setShowAddForm] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');
    const [passwordRules, setPasswordRules] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false
    });
    const [currentUserRole, setCurrentUserRole] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState({
        isOpen: false,
        type: null, // 'add', 'update', 'delete'
        data: null,
        title: '',
        message: ''
    });

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        setFilteredUsers(
            users.filter(user => {
                const matchesSearch = (
                    (user.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                    (user.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                    (user.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase())
                );
                
                const matchesVerification = filters.isVerified === 'all' ? true :
                    filters.isVerified === 'verified' ? user.isVerified :
                    !user.isVerified;
                
                const matchesSubscription = filters.hasSubscription === 'all' ? true :
                    filters.hasSubscription === 'active' ? user.subscription?.isActive :
                    !user.subscription?.isActive;
                
                const matchesRole = filters.role === 'all' ? true :
                    filters.role === 'basicRole' ? user.role === ROLES.BASIC :
                    filters.role === 'adminRole' ? user.role === ROLES.ADMIN :
                    user.role === ROLES.SUPER_ADMIN;
                
                return matchesSearch && matchesVerification && matchesSubscription && matchesRole;
            })
        );
    }, [searchTerm, users, filters]);

    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        try {
            const decodedToken = decodeToken(token);
            setCurrentUserRole(decodedToken?.role || ROLES.BASIC);

            const response = await fetch(`${API_URL}/users`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Erreur lors de la récupération des utilisateurs');
            const data = await response.json();
            setUsers(data);
            setFilteredUsers(data);
        } catch (error) {
            console.error('Erreur:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        showConfirmation('add', newUser);
    };

    const handleUpdateUser = async (userId, updates) => {
        showConfirmation('update', updates);
    };

    const handleDeleteUser = (user) => {
        showConfirmation('delete', user);
    };

    const handleConfirmAction = async () => {
        const token = localStorage.getItem('token');
        const { type, data } = confirmationModal;

        try {
            let response;
            
            switch(type) {
                case 'add':
                    response = await fetch(`${API_URL}/users/add`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    });
                    if (response.ok) {
                        const addedUser = await response.json();
                        setUsers([...users, addedUser]);
                        setShowAddForm(false);
                        setNewUser({ email: '', password: '', firstName: '', lastName: '', phone: '' });
                    }
                    break;

                case 'update':
                    response = await fetch(`${API_URL}/users/${data._id}`, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    });
                    if (response.ok) {
                        const updatedUser = await response.json();
                        setUsers(users.map(user => user._id === updatedUser._id ? updatedUser : user));
                        setSelectedUser(null);
                    }
                    break;

                case 'delete':
                    response = await fetch(`${API_URL}/users/${data._id}`, {
                        method: 'DELETE',
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    if (response.ok) {
                        setUsers(users.filter(user => user._id !== data._id));
                    }
                    break;
            }

            if (!response.ok) throw new Error('Erreur lors de l\'opération');
            
            setUpdateMessage(`Opération effectuée avec succès`);
            setTimeout(() => setUpdateMessage(''), 3000);
        } catch (error) {
            console.error('Erreur:', error);
            setUpdateMessage(`Erreur: ${error.message}`);
        } finally {
            setConfirmationModal({ isOpen: false, type: null, data: null, title: '', message: '' });
        }
    };

    const handleRoleUpdate = async (userId, newRole) => {
        const token = localStorage.getItem('token');
        try {
            console.log('Current user role:', currentUserRole);
            console.log('New role:', newRole);

            const response = await fetch(`${API_URL}/users/${userId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ role: newRole })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour du rôle');
            }

            const updatedUser = await response.json();
            setUsers(prevUsers => 
                prevUsers.map(user => 
                    user._id === userId ? { ...user, role: newRole } : user
                )
            );
            
            setUpdateMessage('Rôle mis à jour avec succès');
            setTimeout(() => setUpdateMessage(''), 3000);
        } catch (error) {
            console.error('Erreur:', error);
            setUpdateMessage(`Erreur: ${error.message}`);
        }
    };

    const checkPasswordRules = (password) => {
        setPasswordRules({
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[!@#$%^&*()\-=+]/.test(password)
        });
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setNewUser({...newUser, password: newPassword});
        checkPasswordRules(newPassword);
    };

    const decodeToken = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (error) {
            console.error('Erreur de décodage du token:', error);
            return null;
        }
    };

    // Séparation des utilisateurs filtrés par rôle
    const adminUsers = filteredUsers.filter(user => 
        user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN
    );
    const normalUsers = filteredUsers.filter(user => 
        user.role === ROLES.BASIC
    );

    // Fonction pour rendre un tableau d'utilisateurs
    const renderUserTable = (users, title, isAdmin = false) => (
        <div className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                {title}
            </h2>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                    Utilisateur
                                </th>
                                {isAdmin && (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                        Rôle
                                    </th>
                                )}
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                    Statut
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                            {users.map(user => (
                                <tr key={user._id} className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div>
                                                <div className="text-sm font-medium text-gray-900 dark:text-white">
                                                    {user.firstName} {user.lastName}
                                                </div>
                                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                                    {user.email}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {isAdmin && (
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                                                {ROLES_LABELS[user.role]}
                                            </span>
                                        </td>
                                    )}
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                            user.isVerified 
                                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                                : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                                        }`}>
                                            {user.isVerified ? 'Vérifié' : 'Non vérifié'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        <button
                                            onClick={() => setSelectedUser(user)}
                                            className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300 mr-4"
                                        >
                                            Éditer
                                        </button>
                                        <button
                                            onClick={() => handleDeleteUser(user)}
                                            className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                                        >
                                            Supprimer
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

    // Fonction pour afficher la modale de confirmation
    const showConfirmation = (type, data) => {
        let title = '';
        let message = '';
        
        switch(type) {
            case 'add':
                title = 'Confirmer l\'ajout';
                message = `Voulez-vous ajouter l'utilisateur ${data.firstName} ${data.lastName} ?`;
                break;
            case 'update':
                title = 'Confirmer la modification';
                message = `Voulez-vous modifier les informations de ${data.firstName} ${data.lastName} ?`;
                break;
            case 'delete':
                title = 'Confirmer la suppression';
                message = `Êtes-vous sûr de vouloir supprimer l'utilisateur ${data.firstName} ${data.lastName} ?`;
                break;
        }

        setConfirmationModal({
            isOpen: true,
            type,
            data,
            title,
            message
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-7xl">
            {/* En-tête avec recherche et filtres */}
            <div className="mb-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                        Gestion des Utilisateurs
                    </h1>
                    <button
                        onClick={() => setShowAddForm(true)}
                        className="inline-flex items-center px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg transition-colors"
                    >
                        <PlusIcon className="w-5 h-5 mr-2" />
                        Nouvel Utilisateur
                    </button>
                </div>

                {/* Barre de recherche et filtres */}
                <div className="flex flex-col md:flex-row gap-4">
                    <div className="relative flex-1">
                        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Rechercher un utilisateur..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                    <div className="flex gap-4">
                        <select
                            value={filters.isVerified}
                            onChange={(e) => setFilters({...filters, isVerified: e.target.value})}
                            className="rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-2"
                        >
                            <option value="all">Tous les statuts</option>
                            <option value="verified">Vérifiés</option>
                            <option value="unverified">Non vérifiés</option>
                        </select>
                        <select
                            value={filters.role}
                            onChange={(e) => setFilters({...filters, role: e.target.value})}
                            className="rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-2"
                        >
                            <option value="all">Tous les rôles</option>
                            <option value="basicRole">Utilisateurs</option>
                            <option value="adminRole">Administrateurs</option>
                            <option value="superAdminRole">Super Admin</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Section Administrateurs */}
            {adminUsers.length > 0 && renderUserTable(adminUsers, "Administrateurs", true)}

            {/* Section Utilisateurs */}
            {normalUsers.length > 0 && renderUserTable(normalUsers, "Utilisateurs")}

            {/* Pagination */}
            <div className="mt-4 flex justify-center">
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                    {Array.from({ length: Math.ceil(filteredUsers.length / USERS_PER_PAGE) }).map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                                currentPage === index + 1
                                    ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                            }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </nav>
            </div>

            {/* Modal Détails Utilisateur */}
            {selectedUser && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center backdrop-blur-sm p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-8 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-8">
                            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                                Modifier l'utilisateur
                            </h2>
                            <button
                                onClick={() => setSelectedUser(null)}
                                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Prénom
                                    </label>
                                    <input
                                        type="text"
                                        value={selectedUser.firstName || ''}
                                        onChange={(e) => setSelectedUser({...selectedUser, firstName: e.target.value})}
                                        className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Nom
                                    </label>
                                    <input
                                        type="text"
                                        value={selectedUser.lastName || ''}
                                        onChange={(e) => setSelectedUser({...selectedUser, lastName: e.target.value})}
                                        className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        value={selectedUser.email || ''}
                                        onChange={(e) => setSelectedUser({...selectedUser, email: e.target.value})}
                                        className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    />
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Téléphone
                                    </label>
                                    <input
                                        type="tel"
                                        value={selectedUser.phone || ''}
                                        onChange={(e) => setSelectedUser({...selectedUser, phone: e.target.value})}
                                        className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Rôle
                                    </label>
                                    <select
                                        value={selectedUser.role}
                                        onChange={(e) => setSelectedUser({...selectedUser, role: e.target.value})}
                                        className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                    >
                                        <option value={ROLES.BASIC}>{ROLES_LABELS[ROLES.BASIC]}</option>
                                        <option value={ROLES.ADMIN}>{ROLES_LABELS[ROLES.ADMIN]}</option>
                                        {currentUserRole === ROLES.SUPER_ADMIN && (
                                            <option value={ROLES.SUPER_ADMIN}>{ROLES_LABELS[ROLES.SUPER_ADMIN]}</option>
                                        )}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                        Statut de vérification
                                    </label>
                                    <button
                                        onClick={() => setSelectedUser({...selectedUser, isVerified: !selectedUser.isVerified})}
                                        className={`w-full px-4 py-3 rounded-lg text-sm font-medium transition-colors ${
                                            selectedUser.isVerified
                                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                                : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                                        }`}
                                    >
                                        {selectedUser.isVerified ? 'Vérifié' : 'Non vérifié'}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end space-x-4 mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
                            <button
                                type="button"
                                onClick={() => setSelectedUser(null)}
                                className="px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={() => handleUpdateUser(selectedUser._id, selectedUser)}
                                className="px-6 py-3 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal pour ajouter un utilisateur */}
            {showAddForm && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center backdrop-blur-sm p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-8 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-8">
                            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                                Ajouter un utilisateur
                            </h2>
                            <button
                                onClick={() => setShowAddForm(false)}
                                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <form onSubmit={handleAddUser} className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            required
                                            value={newUser.email}
                                            onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                                            className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            Prénom
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={newUser.firstName}
                                            onChange={(e) => setNewUser({...newUser, firstName: e.target.value})}
                                            className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            Nom
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            value={newUser.lastName}
                                            onChange={(e) => setNewUser({...newUser, lastName: e.target.value})}
                                            className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                        />
                                    </div>
                                </div>

                                <div className="space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            Mot de passe
                                        </label>
                                        <input
                                            type="password"
                                            required
                                            value={newUser.password}
                                            onChange={handlePasswordChange}
                                            className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                        />
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                                                Le mot de passe doit contenir :
                                            </p>
                                            <ul className="space-y-1 text-sm">
                                                {[
                                                    { key: 'length', text: 'Au moins 8 caractères' },
                                                    { key: 'uppercase', text: 'Une lettre majuscule' },
                                                    { key: 'lowercase', text: 'Une lettre minuscule' },
                                                    { key: 'number', text: 'Un chiffre' },
                                                    { key: 'specialChar', text: 'Un caractère spécial (!@#$%^&*()-=+)' }
                                                ].map(rule => (
                                                    <li key={rule.key} className={`flex items-center ${
                                                        passwordRules[rule.key] ? 'text-green-600 dark:text-green-400' : 'text-gray-500 dark:text-gray-400'
                                                    }`}>
                                                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            {passwordRules[rule.key] ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                            )}
                                                        </svg>
                                                        {rule.text}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            Téléphone
                                        </label>
                                        <input
                                            type="tel"
                                            value={newUser.phone}
                                            onChange={(e) => setNewUser({...newUser, phone: e.target.value})}
                                            className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 px-4 py-3 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end space-x-4 mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
                                <button
                                    type="button"
                                    onClick={() => setShowAddForm(false)}
                                    className="px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-6 py-3 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
                                >
                                    Ajouter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modale de confirmation */}
            {confirmationModal.isOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center backdrop-blur-sm p-4 z-[60]">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6 w-full max-w-md">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                            {confirmationModal.title}
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300 mb-6">
                            {confirmationModal.message}
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setConfirmationModal({ isOpen: false, type: null, data: null, title: '', message: '' })}
                                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-lg transition-colors"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleConfirmAction}
                                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
                            >
                                Confirmer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserManagement;
