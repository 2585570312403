import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import OrganisationList from './pages/OrganisationList';
import AddEditOrganisation from './pages/AddEditOrganisation';
import OrganisationDetails from "./pages/OrganisationDetails";
import Header from "./header/header";
import ProjectDetails from "./pages/ProjectDetails";
import ProjectManagement from "./pages/ProjectManagement";
import UserManagement from "./pages/UserManagement";
import LogsPage from "./pages/LogsPage";
import DashboardPage from './pages/DashboardPage';
import ServicesHealthPage from './pages/ServicesHealthPage';
import ApplicationCatalog from './pages/ApplicationCatalog';
import { API_URL } from './pages/LoginPage';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [darkMode, setDarkMode] = useState(() => 
        localStorage.getItem('darkMode') === 'true'
    );

    // Vérifier l'authentification et l'autorisation au chargement
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            const userRole = localStorage.getItem('userRole');

            if (token) {
                try {
                    // Vérifier si le token est valide
                    const response = await fetch(`${API_URL}/users/me`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    if (response.ok) {
                        const userData = await response.json();
                        const isAdmin = userData.role === 'admin' || userData.role === 'superAdmin';
                        setIsAuthenticated(true);
                        setIsAuthorized(isAdmin);
                    } else {
                        handleLogout();
                    }
                } catch (error) {
                    handleLogout();
                }
            }
        };

        checkAuth();
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const handleLoginSuccess = (token) => {
        setIsAuthenticated(true);
        setIsAuthorized(true);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userRole');
        setIsAuthenticated(false);
        setIsAuthorized(false);
    };

    return (
        <Router>
            <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
                {isAuthenticated && isAuthorized && (
                    <Header onLogout={handleLogout} darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                )}
                <main className="pt-16">
                    <Routes>
                        <Route path="/" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
                        {isAuthenticated && isAuthorized ? (
                            <>
                                <Route path="/home" element={<HomePage />} />
                                <Route path="/user" element={<UserManagement />} />
                                <Route path="/organisations" element={<OrganisationList />} />
                                <Route path="/organisations/add" element={<AddEditOrganisation />} />
                                <Route path="/organisations/edit/:orgId" element={<AddEditOrganisation />} />
                                <Route path="/organisations/:orgId" element={<OrganisationDetails />} />
                                <Route path="/organisations/:orgId/projects" element={<OrganisationDetails />} />
                                <Route path="/organisations/:orgId/project/:projectId" element={<ProjectDetails />} />
                                <Route path="/organisations/:orgId/project/:projectId/settings" element={<ProjectDetails />} />
                                <Route path="/organisations/:orgId/project/:projectId/analytics" element={<ProjectDetails />} />
                                <Route path="/logs" element={<LogsPage />} />
                                <Route path="/dashboard" element={<DashboardPage />} />
                                <Route path="/services-health" element={<ServicesHealthPage />} />
                                <Route path="/applications" element={<ApplicationCatalog />} />
                            </>
                        ) : (
                            <Route path="*" element={<Navigate to="/" replace />} />
                        )}
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
