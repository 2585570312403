// src/pages/ApplicationCatalog.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API_URL from '../config/api';

function ApplicationCatalog() {
    const [applications, setApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [appToDelete, setAppToDelete] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [newApp, setNewApp] = useState({
        name: '',
        description: '',
        category: '',
        version: '',
        minimumSystemRequirements: {
            os: '',
            processor: '',
            memory: '',
            storage: ''
        }
    });

    const [showVersionForm, setShowVersionForm] = useState(false);
    const [selectedApp, setSelectedApp] = useState(null);
    const [newVersion, setNewVersion] = useState({
        version: '',
        file: null
    });

    const [isDeletingApp, setIsDeletingApp] = useState(null);

    // Ajoutez ces états pour gérer les étapes
    const [formStep, setFormStep] = useState(1);
    const totalSteps = 3;

    // Ajoutez un état pour suivre si le champ a été touché
    const [versionTouched, setVersionTouched] = useState(false);

    // Ajoutez un état local pour l'erreur de version
    const [versionError, setVersionError] = useState('');

    useEffect(() => {
        fetchApplications();
    }, []);

    const fetchApplications = async () => {
        try {
            const response = await fetch(`${API_URL}/catalog/apps`, {
                headers: {
                    'Authorization': `${localStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            setApplications(data);
        } catch (error) {
            console.error('Erreur:', error);
            setError('Erreur lors du chargement des applications');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            console.log('File selected:', {
                name: file.name,
                type: file.type,
                size: file.size
            });
            setSelectedFile(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!selectedFile) {
            setError('Veuillez sélectionner un fichier');
            return;
        }

        if (!newApp.name || !newApp.description || !newApp.category || !newApp.version) {
            setError('Tous les champs obligatoires doivent tre remplis');
            return;
        }

        try {
            // Génération d'un appId unique
            const appId = crypto.randomUUID();

            // Création du FormData pour l'upload
            const formData = new FormData();
            formData.append('file', selectedFile);
            
            // Logs de débogage
            console.log('Upload details:', {
                appId,
                version: newApp.version,
                fileName: selectedFile.name,
                fileSize: selectedFile.size,
                fileType: selectedFile.type
            });

            // Construction de l'URL avec les paramètres requis
            const uploadUrl = `${API_URL}/storage/upload/${appId}/${newApp.version}`;
            console.log('Upload URL:', uploadUrl);

            // Upload du fichier
            const uploadResponse = await fetch(uploadUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `${localStorage.getItem('token')}`
                },
                body: formData
            });

            if (!uploadResponse.ok) {
                const errorData = await uploadResponse.json();
                throw new Error(errorData.message || 'Erreur lors de l\'upload du fichier');
            }

            const fileData = await uploadResponse.json();
            console.log('Upload response:', fileData);

            // Création de l'application dans le catalogue
            const catalogData = {
                ...newApp,
                appId: appId,
                fileName: selectedFile.name,
                downloadUrl: fileData.downloadUrl,
                size: selectedFile.size
            };

            console.log('Catalog data:', catalogData);

            const appResponse = await fetch(`${API_URL}/catalog/apps`, {
                method: 'POST',
                headers: {
                    'Authorization': `${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(catalogData)
            });

            if (!appResponse.ok) {
                // Si la création échoue, on nettoie le fichier uploadé
                await fetch(`${API_URL}/storage/${appId}/${newApp.version}/${selectedFile.name}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`
                    }
                });
                const errorData = await appResponse.json();
                throw new Error(errorData.message || 'Erreur lors de la création de l\'application');
            }

            await fetchApplications();
            setShowAddForm(false);
            resetForm();
        } catch (error) {
            console.error('Erreur complète:', error);
            setError(error.message);
        }
    };

    const resetForm = () => {
        setNewApp({
            name: '',
            description: '',
            category: '',
            version: '',
            minimumSystemRequirements: {
                os: '',
                processor: '',
                memory: '',
                storage: ''
            }
        });
        setSelectedFile(null);
        setVersionTouched(false);
        setVersionError('');
    };

    // Validation du format de version
    const validateVersion = (version) => {
        const versionRegex = /^\d+\.\d+\.\d+$/;
        return versionRegex.test(version);
    };

    // Gestionnaire de changement de version
    const handleVersionChange = (e) => {
        const version = e.target.value;
        setNewApp(prev => ({...prev, version: version}));
        setVersionTouched(true);
        
        if (versionTouched && version && !validateVersion(version)) {
            setVersionError('Format de version invalide. Utilisez le format x.y.z');
        } else {
            setVersionError('');
        }
    };

    const handleNewVersion = async (app) => {
        setSelectedApp(app);
        setShowVersionForm(true);
    };

    const handleVersionFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            console.log('Version file selected:', {
                name: file.name,
                type: file.type,
                size: file.size
            });
            setNewVersion(prev => ({ ...prev, file }));
        }
    };

    const handleVersionSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!newVersion.file || !newVersion.version) {
            setError('Le fichier et la version sont requis');
            return;
        }

        try {
            // 1. Upload du fichier
            const formData = new FormData();
            formData.append('file', newVersion.file);

            console.log('Uploading new version:', {
                appId: selectedApp.appId,
                version: newVersion.version,
                fileName: newVersion.file.name
            });

            const uploadResponse = await fetch(
                `${API_URL}/storage/upload/${selectedApp.appId}/${newVersion.version}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`
                    },
                    body: formData
                }
            );

            if (!uploadResponse.ok) {
                const errorData = await uploadResponse.json();
                throw new Error(errorData.message || 'Erreur lors de l\'upload du fichier');
            }

            const fileData = await uploadResponse.json();

            // 2. Mise à jour du catalogue
            const updateResponse = await fetch(
                `${API_URL}/catalog/apps/${selectedApp._id}/version`,
                {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        version: newVersion.version,
                        fileName: fileData.fileName,
                        downloadUrl: fileData.downloadUrl
                    })
                }
            );

            if (!updateResponse.ok) {
                throw new Error('Erreur lors de la mise à jour du catalogue');
            }

            await fetchApplications();
            setShowVersionForm(false);
            setNewVersion({ version: '', file: null });
            setSelectedApp(null);
        } catch (error) {
            console.error('Erreur:', error);
            setError(error.message);
        }
    };

    const handleDownload = async (app) => {
        try {
            setIsDownloading(true);
            const response = await fetch(
                `${API_URL}/storage/metadata/${app.appId}/${app.version}/${app.fileName}`,
                {
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération du lien de téléchargement');
            }

            const data = await response.json();
            
            if (data.exists && data.metadata.downloadUrl) {
                window.location.href = data.metadata.downloadUrl;
            } else {
                throw new Error('Fichier non disponible');
            }
        } catch (error) {
            console.error('Erreur de téléchargement:', error);
            setError(error.message);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleDelete = async (app) => {
        setAppToDelete(app);
        setShowDeleteConfirm(true);
    };

    const confirmDelete = async () => {
        try {
            setIsDeletingApp(appToDelete._id);

            // 1. Suppression du fichier dans le Storage Service
            const storageResponse = await fetch(
                `${API_URL}/storage/${appToDelete.appId}/${appToDelete.version}/${appToDelete.fileName}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`
                    }
                }
            );

            if (!storageResponse.ok) {
                throw new Error('Erreur lors de la suppression du fichier');
            }

            // 2. Suppression de l'application dans le Catalog Service
            const catalogResponse = await fetch(
                `${API_URL}/catalog/apps/${appToDelete.appId}/${appToDelete.version}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `${localStorage.getItem('token')}`
                    }
                }
            );

            if (!catalogResponse.ok) {
                throw new Error('Erreur lors de la suppression de l\'application');
            }

            // 3. Mise à jour de la liste des applications
            await fetchApplications();
            setError('');
            setShowDeleteConfirm(false);
            setAppToDelete(null);
        } catch (error) {
            console.error('Erreur de suppression:', error);
            setError(`Erreur lors de la suppression: ${error.message}`);
        } finally {
            setIsDeletingApp(null);
        }
    };

    // Ajoutez cette fonction de validation
    const validateCurrentStep = () => {
        switch (formStep) {
            case 1:
                return newApp.name.trim() !== '' && 
                       newApp.description.trim() !== '' && 
                       newApp.category.trim() !== '';
            case 2:
                return newApp.version.trim() !== '' && 
                       validateVersion(newApp.version);
            case 3:
                return selectedFile !== null;
            default:
                return false;
        }
    };

    // Modifiez la fonction nextStep
    const nextStep = () => {
        if (formStep < totalSteps && validateCurrentStep()) {
            setFormStep(formStep + 1);
        }
    };

    const prevStep = () => {
        if (formStep > 1) {
            setFormStep(formStep - 1);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
            {/* Header */}
            <div className="bg-white dark:bg-gray-800 shadow">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                            Catalogue d'Applications
                        </h1>
                        <button
                            onClick={() => setShowAddForm(true)}
                            className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white text-sm font-medium rounded-md shadow-sm transition-transform duration-200 transform hover:scale-105"
                        >
                            <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                            </svg>
                            Ajouter une application
                        </button>
                    </div>
                </div>
            </div>

            {/* Main content */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {error && (
                    <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 rounded">
                        <div className="flex">
                            <svg className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                            {error}
                        </div>
                    </div>
                )}

                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {applications.map((app) => (
                            <div key={app._id} className="bg-white dark:bg-gray-800 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 transform hover:-translate-y-1">
                                <div className="p-6">
                                    <div className="flex justify-between items-start">
                                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{app.name}</h3>
                                        <button
                                            onClick={() => handleDelete(app)}
                                            className="text-gray-400 hover:text-red-500 transition-colors duration-200"
                                            disabled={isDeletingApp === app._id}
                                        >
                                            <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </button>
                                    </div>
                                    <p className="mt-2 text-gray-600 dark:text-gray-400">{app.description}</p>
                                    <div className="mt-4">
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                                            v{app.version}
                                        </span>
                                    </div>
                                    <div className="mt-4 flex justify-end space-x-3">
                                        <button
                                            onClick={() => handleNewVersion(app)}
                                            className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-800"
                                        >
                                            <svg className="h-4 w-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Nouvelle version
                                        </button>
                                        <button
                                            onClick={() => handleDownload(app)}
                                            disabled={isDownloading}
                                            className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-800"
                                        >
                                            {isDownloading ? (
                                                <svg className="animate-spin h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                                </svg>
                                            ) : (
                                                <svg className="h-4 w-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                                </svg>
                                            )}
                                            Télécharger
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Modal de confirmation de suppression amélioré */}
            {showDeleteConfirm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full mx-4 shadow-lg transform transition-all duration-300 scale-95">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                            Confirmer la suppression
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400 mb-6">
                            Êtes-vous sûr de vouloir supprimer {appToDelete?.name} (version {appToDelete?.version}) ? Cette action est irréversible.
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => {
                                    setShowDeleteConfirm(false);
                                    setAppToDelete(null);
                                }}
                                className="px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 rounded-md transition-colors duration-200"
                                disabled={isDeletingApp === appToDelete?._id}
                            >
                                Annuler
                            </button>
                            <button
                                onClick={confirmDelete}
                                disabled={isDeletingApp === appToDelete?._id}
                                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 flex items-center"
                            >
                                {isDeletingApp === appToDelete?._id ? (
                                    <>
                                        <svg className="animate-spin h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                        </svg>
                                        Suppression...
                                    </>
                                ) : (
                                    'Supprimer'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Formulaire de nouvelle version amélioré */}
            {showVersionForm && selectedApp && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-full max-w-2xl shadow-lg transform transition-all duration-300 scale-95">
                        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
                            Nouvelle version pour {selectedApp.name}
                        </h2>
                        <form onSubmit={handleVersionSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-gray-300">Version</label>
                                <input
                                    type="text"
                                    required
                                    placeholder="x.y.z"
                                    pattern="^\d+\.\d+\.\d+$"
                                    value={newVersion.version}
                                    onChange={(e) => setNewVersion(prev => ({
                                        ...prev,
                                        version: e.target.value
                                    }))}
                                    className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                />
                                <p className="text-xs text-gray-500 mt-1">Format: x.y.z (ex: 1.0.1)</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-gray-300">Fichier</label>
                                <input
                                    type="file"
                                    required
                                    onChange={handleVersionFileChange}
                                    className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                    accept="application/x-msdownload,application/x-executable,application/octet-stream"
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowVersionForm(false);
                                        setSelectedApp(null);
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 rounded"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                                >
                                    Ajouter la version
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Formulaire de nouvelle application amélioré */}
            {showAddForm && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-xl p-8 w-full max-w-2xl shadow-lg transform transition-all duration-300 scale-95">
                        {/* Header avec progression */}
                        <div className="mb-8">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
                                {formStep === 1 && "Informations de base"}
                                {formStep === 2 && "Spécifications techniques"}
                                {formStep === 3 && "Fichier d'installation"}
                            </h2>
                            {/* Barre de progression */}
                            <div className="relative">
                                {/* Ligne de progression avec z-index négatif */}
                                <div className="absolute top-4 left-0 right-0 h-0.5 bg-gray-200 dark:bg-gray-700 -z-10">
                                    <div
                                        className="absolute h-0.5 bg-indigo-500 transition-all duration-200"
                                        style={{ width: `${((formStep - 1) / (totalSteps - 1)) * 100}%` }}
                                    />
                                </div>

                                {/* Points de progression */}
                                <div className="flex justify-between">
                                    {[1, 2, 3].map((step) => (
                                        <span
                                            key={step}
                                            className={`w-8 h-8 flex items-center justify-center rounded-full 
                                                ${step === formStep 
                                                    ? 'bg-indigo-600 text-white'
                                                    : step < formStep
                                                        ? 'bg-indigo-600 text-white'
                                                        : 'bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400'
                                                } transition-all duration-200`}
                                        >
                                            {step < formStep ? (
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                </svg>
                                            ) : (
                                                <span>{step}</span>
                                            )}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Étape 1: Informations de base */}
                            <div className={`space-y-4 transition-all duration-300 ${formStep === 1 ? 'block' : 'hidden'}`}>
                                <div>
                                    <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                        Nom de l'application
                                    </label>
                                    <input
                                        type="text"
                                        value={newApp.name}
                                        onChange={(e) => setNewApp({...newApp, name: e.target.value})}
                                        className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
                                        placeholder="Ex: Mon Application"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                        Description
                                    </label>
                                    <textarea
                                        value={newApp.description}
                                        onChange={(e) => setNewApp({...newApp, description: e.target.value})}
                                        className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
                                        rows="3"
                                        placeholder="Décrivez votre application..."
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                        Catégorie
                                    </label>
                                    <input
                                        type="text"
                                        value={newApp.category}
                                        onChange={(e) => setNewApp({...newApp, category: e.target.value})}
                                        className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
                                        placeholder="Ex: Productivité"
                                    />
                                </div>
                            </div>

                            {/* Étape 2: Spécifications techniques */}
                            <div className={`space-y-4 transition-all duration-300 ${formStep === 2 ? 'block' : 'hidden'}`}>
                                <div>
                                    <label className="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">
                                        Version
                                    </label>
                                    <input
                                        type="text"
                                        value={newApp.version}
                                        onChange={handleVersionChange}
                                        onBlur={() => setVersionTouched(true)}
                                        placeholder="x.y.z"
                                        className={`w-full px-4 py-2 rounded-lg border ${
                                            versionTouched && newApp.version && !validateVersion(newApp.version)
                                                ? 'border-red-500 focus:ring-red-500'
                                                : 'border-gray-300 dark:border-gray-600'
                                        } focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-white`}
                                    />
                                    <p className="mt-1 text-sm text-gray-500">Format: x.y.z (ex: 1.0.0)</p>
                                    {versionError && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {versionError}
                                        </p>
                                    )}
                                </div>
                                {/* Ajoutez ici d'autres champs techniques si nécessaire */}
                            </div>

                            {/* Étape 3: Upload de fichier */}
                            <div className={`space-y-4 transition-all duration-300 ${formStep === 3 ? 'block' : 'hidden'}`}>
                                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-6 text-center">
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="hidden"
                                        id="file-upload"
                                        accept="application/x-msdownload,application/x-executable,application/octet-stream"
                                    />
                                    <label
                                        htmlFor="file-upload"
                                        className="cursor-pointer flex flex-col items-center justify-center"
                                    >
                                        <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        <span className="mt-2 text-gray-600 dark:text-gray-400">
                                            {selectedFile ? selectedFile.name : "Cliquez pour sélectionner un fichier"}
                                        </span>
                                        <span className="mt-1 text-sm text-gray-500">
                                            Formats acceptés : .exe, .msi, .zip
                                        </span>
                                    </label>
                                </div>
                            </div>

                            {/* Navigation entre les étapes */}
                            <div className="flex justify-between pt-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (formStep === 1) {
                                            setShowAddForm(false);
                                            setFormStep(1);
                                        } else {
                                            prevStep();
                                        }
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 rounded transition-colors duration-200"
                                >
                                    {formStep === 1 ? 'Annuler' : 'Précédent'}
                                </button>
                                <button
                                    type={formStep === totalSteps ? 'submit' : 'button'}
                                    onClick={() => formStep < totalSteps && nextStep()}
                                    disabled={!validateCurrentStep()}
                                    className={`px-6 py-2 rounded-lg shadow-sm transition-all duration-200
                                        ${validateCurrentStep()
                                            ? 'bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white'
                                            : 'bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400'
                                        }`}
                                >
                                    {formStep === totalSteps ? 'Créer l\'application' : 'Suivant'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ApplicationCatalog;