// components/LogsPage.js
import React, { useState, useEffect } from 'react';
export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.api.orbis-france.fr';


function LogsPage() {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const LOGS_PER_PAGE = 10;

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_URL}/logs`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Erreur lors de la récupération des logs');
            const data = await response.json();
            setLogs(data);
        } catch (error) {
            console.error('Erreur:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const filteredLogs = logs.filter(log => 
        log.route.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.method.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.statusCode.toString().includes(searchTerm)
    );

    const getStatusColor = (statusCode) => {
        if (statusCode < 300) return 'text-green-600 dark:text-green-400 bg-green-100 dark:bg-green-900/50';
        if (statusCode < 400) return 'text-blue-600 dark:text-blue-400 bg-blue-100 dark:bg-blue-900/50';
        if (statusCode < 500) return 'text-yellow-600 dark:text-yellow-400 bg-yellow-100 dark:bg-yellow-900/50';
        return 'text-red-600 dark:text-red-400 bg-red-100 dark:bg-red-900/50';
    };

    const getMethodColor = (method) => {
        switch (method.toUpperCase()) {
            case 'GET':
                return 'text-blue-600 dark:text-blue-400 bg-blue-100 dark:bg-blue-900/50';
            case 'POST':
                return 'text-green-600 dark:text-green-400 bg-green-100 dark:bg-green-900/50';
            case 'PUT':
                return 'text-yellow-600 dark:text-yellow-400 bg-yellow-100 dark:bg-yellow-900/50';
            case 'DELETE':
                return 'text-red-600 dark:text-red-400 bg-red-100 dark:bg-red-900/50';
            default:
                return 'text-gray-600 dark:text-gray-400 bg-gray-100 dark:bg-gray-900/50';
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen dark:bg-gray-900">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 dark:border-indigo-400"></div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="flex justify-between items-center mb-8">
                <div>
                    <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Logs système</h2>
                    <div className="h-1 w-20 bg-indigo-600 dark:bg-indigo-500 mt-2"></div>
                </div>
            </div>

            {/* Barre de recherche */}
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Rechercher dans les logs..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-4 py-3 rounded-xl border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white transition-colors duration-200"
                />
            </div>

            {/* Liste des logs */}
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {filteredLogs
                    .slice((currentPage - 1) * LOGS_PER_PAGE, currentPage * LOGS_PER_PAGE)
                    .map((log, index) => (
                        <div 
                            key={index}
                            className="bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-6 border border-gray-100 dark:border-gray-700"
                        >
                            <div className="space-y-4">
                                <div className="flex justify-between items-start">
                                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${getMethodColor(log.method)}`}>
                                        {log.method}
                                    </span>
                                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(log.statusCode)}`}>
                                        {log.statusCode}
                                    </span>
                                </div>

                                <div className="space-y-2">
                                    <div className="flex items-center space-x-2">
                                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Route:</span>
                                        <span className="text-sm text-gray-900 dark:text-white font-mono">{log.route}</span>
                                    </div>

                                    <div className="flex items-center space-x-2">
                                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">Temps:</span>
                                        <span className="text-sm text-gray-900 dark:text-white">
                                            {log.responseTime}ms
                                        </span>
                                    </div>
                                </div>

                                <div className="text-xs text-gray-500 dark:text-gray-400 pt-2 border-t border-gray-100 dark:border-gray-700">
                                    {new Date(log.timestamp).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

            {/* Pagination */}
            <div className="mt-6 flex justify-center">
                <nav className="flex space-x-2">
                    {Array.from({ length: Math.ceil(filteredLogs.length / LOGS_PER_PAGE) }).map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`px-3 py-1 rounded-md ${
                                currentPage === index + 1
                                    ? 'bg-indigo-600 text-white'
                                    : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
                            } transition-colors duration-200`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </nav>
            </div>
        </div>
    );
}

export default LogsPage;
