import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useOrganisations } from '../hooks/useApi';
import { useDebounce } from '../hooks/useDebounce';
import API_URL from '../config/api';

function OrganisationList() {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const debouncedSearch = useDebounce(searchTerm, 300);

    const { data: organisations, isLoading, error, refetch } = useOrganisations(currentPage, 10);

    const handleDelete = async (orgId) => {
        if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette organisation ?')) return;
        
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${API_URL}/organisations/${orgId}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Erreur lors de la suppression');
            refetch(); // Actualise la liste après suppression
        } catch (error) {
            console.error('Erreur:', error);
            alert('Erreur lors de la suppression de l\'organisation');
        }
    };

    const filteredOrganisations = useMemo(() => {
        if (!organisations?.data) return [];
        return organisations.data.filter(org =>
            org.name.toLowerCase().includes(debouncedSearch.toLowerCase())
        );
    }, [organisations, debouncedSearch]);

    if (isLoading) return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
        </div>
    );
    
    if (error) return (
        <div className="text-center text-red-600 p-4">
            Erreur: {error.message}
        </div>
    );

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="mb-8 animate-fade-in">
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4 bg-clip-text text-transparent animate-gradient-smooth bg-[length:200%_auto] bg-gradient-to-r from-indigo-500 via-purple-600 to-indigo-500">
                    Organisations
                </h1>
                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-lg transition-all duration-300 group">
                    <div className="flex items-start space-x-4">
                        <div className="p-2 bg-indigo-100 dark:bg-indigo-900/50 rounded-lg group-hover:scale-110 transition-transform duration-300">
                            <svg className="w-6 h-6 text-indigo-600 dark:text-indigo-400 group-hover:animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                            </svg>
                        </div>
                        <div className="group-hover:translate-x-1 transition-transform duration-300">
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2 group-hover:text-indigo-500 dark:group-hover:text-indigo-400 transition-colors duration-300">
                                Gestion des clients IA
                            </h2>
                            <p className="text-gray-600 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-300 transition-colors duration-300">
                                Cette page liste l'ensemble des clients qui ont fait appel à nos services pour développer une IA sur mesure. 
                                Chaque organisation représente un projet unique d'intelligence artificielle adapté aux besoins spécifiques du client.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-6 animate-fade-in-up" style={{ animationDelay: '0.2s' }}>
                <div className="relative group">
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-300"></div>
                    <input
                        type="text"
                        placeholder="Rechercher une organisation..."
                        className="relative w-full px-4 py-3 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-transparent transition-all duration-300 placeholder-gray-400 dark:placeholder-gray-500"
                    />
                </div>
            </div>

            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {filteredOrganisations.map((org, index) => (
                    <div 
                        key={org._id} 
                        className="animate-fade-in-up"
                        style={{ animationDelay: `${0.1 * (index + 1)}s` }}
                    >
                        <div className="group relative transform hover:-translate-y-1 transition-all duration-300 rounded-xl">
                            <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 rounded-xl opacity-0 group-hover:opacity-100 transition duration-300 blur-sm group-hover:blur"></div>
                            <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-sm group-hover:shadow-xl transition-all duration-300">
                                <Link 
                                    to={`/organisations/${org._id}`}
                                    className="block p-6 hover:bg-gray-50/50 dark:hover:bg-gray-700/50 transition-colors duration-200 rounded-t-xl"
                                >
                                    <div className="flex justify-between items-start">
                                        <div className="space-y-2">
                                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-indigo-500 dark:group-hover:text-indigo-400 transition-colors duration-200">
                                                {org.name}
                                            </h3>
                                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                                Voir les détails →
                                            </p>
                                        </div>
                                        <span className="px-3 py-1 bg-indigo-100 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400 rounded-full text-xs font-medium">
                                            Organisation
                                        </span>
                                    </div>
                                </Link>

                                <div className="px-6 py-3 border-t border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-700/50 rounded-b-xl">
                                    <div className="flex justify-end space-x-2">
                                        <Link 
                                            to={`/organisations/edit/${org._id}`}
                                            className="p-2 text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-white dark:hover:bg-gray-600 rounded-lg transition-all duration-200"
                                            title="Modifier l'organisation"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                        </Link>
                                        <button 
                                            onClick={() => handleDelete(org._id)}
                                            className="p-2 text-gray-600 dark:text-gray-400 hover:text-red-600 dark:hover:text-red-400 hover:bg-white dark:hover:bg-gray-600 rounded-lg transition-all duration-200"
                                            title="Supprimer l'organisation"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OrganisationList;
