import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config/api';

const services = [
    { name: 'Paiement', endpoint: `${API_URL}/subscriptions/health`, description: 'Gestion des abonnements et paiements' },
    { name: 'Authentification', endpoint: `${API_URL}/auth/health`, description: 'Gestion des connexions et tokens' },
    { name: 'Logs', endpoint: `${API_URL}/logs/health`, description: 'Système de journalisation' },
    { name: 'Projets', endpoint: `${API_URL}/projects/health`, description: 'Gestion des projets' },
    { name: 'Organisations', endpoint: `${API_URL}/organisations/health`, description: 'Gestion des organisations' },
    { name: 'Utilisateurs', endpoint: `${API_URL}/users/health`, description: 'Gestion des utilisateurs' },
    { name: 'STT', endpoint: `${API_URL}/stt/health`, description: 'Speech-to-Text' },
    { name: 'TTS', endpoint: `${API_URL}/tts/health`, description: 'Text-to-Speech' },
    { name: 'Rate Limiter', endpoint: `${API_URL}/limit/health`, description: 'Gestion des limitations d\'API' },
    { name: 'Voice Bridge', endpoint: `${API_URL}/voice-bridge/health`, description: 'Service de pont vocal' }
];

function ServicesHealthPage() {
    const [servicesHealth, setServicesHealth] = useState({});
    const [loading, setLoading] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);

    const checkServiceHealth = async (endpoint) => {
        try {
            const response = await axios.get(endpoint);
            return response.status === 200;
        } catch (error) {
            console.error(`Erreur lors de la vérification de ${endpoint}:`, error);
            return false;
        }
    };

    const checkAllServices = async () => {
        setLoading(true);
        const health = {};
        
        await Promise.all(
            services.map(async (service) => {
                health[service.name] = await checkServiceHealth(service.endpoint);
            })
        );

        setServicesHealth(health);
        setLastUpdate(new Date());
        setLoading(false);
    };

    useEffect(() => {
        checkAllServices();
        // Vérifier toutes les 30 secondes
        const interval = setInterval(checkAllServices, 30000);
        return () => clearInterval(interval);
    }, []);

    const getStatusBadge = (isHealthy) => {
        return isHealthy ? (
            <span className="px-2 py-1 text-sm font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                Opérationnel
            </span>
        ) : (
            <span className="px-2 py-1 text-sm font-semibold rounded-full bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                Hors service
            </span>
        );
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
                    État des Services
                </h1>
                <div className="flex items-center gap-4">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                        Dernière mise à jour: {lastUpdate ? new Date(lastUpdate).toLocaleTimeString() : '-'}
                    </span>
                    <button
                        onClick={checkAllServices}
                        disabled={loading}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
                    >
                        {loading ? 'Actualisation...' : 'Actualiser'}
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {services.map((service) => (
                    <div
                        key={service.name}
                        className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6"
                    >
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                                    {service.name}
                                </h2>
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {service.description}
                                </p>
                            </div>
                            {loading ? (
                                <div className="animate-pulse h-6 w-24 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                            ) : (
                                getStatusBadge(servicesHealth[service.name])
                            )}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                            Endpoint: {service.endpoint}
                        </div>
                    </div>
                ))}
            </div>

            {/* Résumé global */}
            <div className="mt-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
                    Résumé Global
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                        <div className="text-sm text-gray-500 dark:text-gray-400">Services Total</div>
                        <div className="text-2xl font-bold text-gray-800 dark:text-white">{services.length}</div>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                        <div className="text-sm text-gray-500 dark:text-gray-400">Opérationnels</div>
                        <div className="text-2xl font-bold text-green-600 dark:text-green-400">
                            {loading ? '-' : Object.values(servicesHealth).filter(Boolean).length}
                        </div>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                        <div className="text-sm text-gray-500 dark:text-gray-400">Hors service</div>
                        <div className="text-2xl font-bold text-red-600 dark:text-red-400">
                            {loading ? '-' : Object.values(servicesHealth).filter(v => !v).length}
                        </div>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4">
                        <div className="text-sm text-gray-500 dark:text-gray-400">Disponibilité</div>
                        <div className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
                            {loading ? '-' : `${((Object.values(servicesHealth).filter(Boolean).length / services.length) * 100).toFixed(1)}%`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesHealthPage; 